<template>
  <div class="container">
    <div class="text-right my-4">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>


  <div class="text-left">
    <v-container fluid>
      <v-row>
        <!-- <v-col cols="6"> -->
          <form>
            <v-row>
              <v-col cols="2">
                  <v-text-field
                    v-model="query"
                    label="Enter Module Name"
                    required
                  ></v-text-field>
              </v-col>
                  <v-col cols="2" class="my-4">
                    <v-select
                      :items="panel_data"
                      :menu-props="{ top: true, offsetY: true }"
                      clearable
                      label="Panels"
                      v-model="p_id"
                      v-on:change="panel_modules(p_id)"
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="3" class="my-4">
                    <v-autocomplete
                      :items="parent_module_data"
                      clearable
                      item-text="text"
                      item-value="value"
                      label= "Select Parent Module"
                      v-model="m_id"
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <!-- </form> -->
                  <!-- </v-col> -->
                  <v-col cols="1" class="my-4">
                      <v-btn small color="primary" @click="searchModules()">
                        Search
                      </v-btn>
                  </v-col>
                  <v-col cols="2" class="my-4">
                    <v-btn small @click="resetSearch()">
                          Reset
                    </v-btn>
                  </v-col>
                  <v-col cols="1" class="my-4">
                      <template>
                          <router-link
                                  :to="{
                                    path: 'add-module',
                                  }"
                                  v-show="checkIfOperationExistForModule('add')"
                                  ><v-btn small color="primary" dark class="mx-2">
                                    Add
                                  </v-btn></router-link>
                          <!-- <v-btn class="mx-2" color="primary" dark v-bind="attrs" v-on="on" v-show="checkIfOperationExistForModule('add')">
                                Add
                              </v-btn> -->
                      </template>
                  </v-col>
                  <v-col cols="1" class="my-4">
                  <v-dialog max-width="600" v-model="dialog2">
                    <template v-slot:activator="{on, attrs}">
                      <v-btn small color="error" v-bind="attrs" v-on="on" v-show="checkIfOperationExistForModule('delete')">Delete</v-btn>
                    </template>
                    <div v-if="deleteItems.length > 0">
                      <template>
                        <v-card>
                          <v-card-text>
                            <div class="text-h3 pa-12">
                              Are you sure you want to delete all the selected Record(s)?
                            </div>
                          </v-card-text>
                          <v-card-actions class="justify-end">
                            <v-form ref="form" method="post">
                              <v-btn text class="confirm_button mx-2" v-on:click="dialog2 = false" @click="deleteModule"
                                >Yes</v-btn
                              >
                            </v-form>
                            <v-btn class="close_button mx-2" text @click="dialog2 = false">
                              Close
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </div>
                    <div v-if="deleteItems.length == 0">
                      <template>
                          <v-card>
                            <v-card-text class="center">
                              <div class="text-h3 pa-12">Please Select Record(s) to delete</div>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                              <v-btn class="close_button" text @click="dialog2 = false">
                                Close
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                      </template>
                    </div>
                  </v-dialog>
                  </v-col>
            </v-row>
          </form>
      <!-- </v-col> -->
    </v-row>
    </v-container>
  </div>

    </div>
    <div class="text-right"></div>
 <!-- v-data-table  -->
    <v-row>
      <v-col cols="12">
          <div class="tableWrapper">
            <div id="select-all-checkbox">
              <v-checkbox
                ref="n"
                v-model="allSelected"
                @click="selectAll"
              ></v-checkbox>
            </div>
            <v-data-table
              :headers="headers"
              item-key="product_id"
              :items="module_data"
              :options.sync="options"
              :server-items-length="total"
              :loading="isLoading"
              class="elevation-1"
              hide-default-footer
            >
              <template v-slot:item.selectCheckbox="{ item }">
                <v-checkbox
                  ref="n"
                  v-model="deleteItems"
                  :value="item.module_id"
                  :key="item.module_id"
                  @click="addToDelete($event, item.module_id)"
                ></v-checkbox>
              </template>
              <template v-slot:item.actions="{ item }">
                <!-- <v-icon
                      medium
                      color="grey"
                      @click="addToUpdate($event, item.module_id)"
                      v-show="checkIfOperationExistForModule('add')"
                    >
                      mdi-pencil
                    </v-icon> -->
                <router-link
                :to="{
                  path: 'edit-module/'+item.module_id,
                }"
                style="text-decoration:none"
                v-show="checkIfOperationExistForModule('edit')"
                >
                <v-icon
                      medium
                      color="grey"
                    >
                      mdi-pencil
                    </v-icon>
                <!-- <v-btn color="primary" dark small v-on="on">
                  Add
                </v-btn> -->
                </router-link>
              </template>
            </v-data-table>
             <div class="text-center pt-4 pb-4">
              <Pagination
            :isloading="isLoading"
            :startRecord="startRecord"
            :currentPage="currentPage"
            :lastPage="lastPage"
            :lastRecord="lastRecord"
            :totRecords="totRecords"
            :isCurrentPageClass="isCurrentPageClass"
            :perpage="perpage"
            :getLastPageClass="getLastPageClass"
            :totPage="totPage"
            :getPages="getPages"
            @handlePerPage="handlePerPage"
            @paginate="paginate"
            @last="last"
            @getDataByPage="getDataByPage"
            :showPerPage="showPerPage"
             />
            </div>
          </div>
      </v-col>
    </v-row>
    <v-row justify="center">
     <v-dialog
      v-model="toggleUpdateModal"
      max-width="600"
    >
      <template>
	  <v-card>
                    <v-card-title>
                      <span class="headline">Update Module</span>
                    </v-card-title>
                    <v-form ref="form" @submit="updateModuleData" method="put">
                      <v-container class="px-50 v-card-padding" fluid>
                        <v-text-field
                          v-model="updateModule.moduleName"
                          label="Module Name"
                          required
                          :rules="nameRules"
                        ></v-text-field>
                        <div style="color: red">
                          {{ upmessage1 }}
                        </div>
                         <v-text-field
                          v-model="updateModule.url_slug"
                          label="Url Slug"
                          :rules="urlRules"
                          required
                        ></v-text-field>
                        <v-select
                          :items="operation"
                          label="Select Operations"
                          multiple
                          v-model="updateModule.operation_id"
                        ></v-select>
                        <v-select
                          :items="parent_id"
                          label="Select Parent"
                          v-model="updateModule.parent_id"
                        ></v-select>
                        <v-select
                          :items="panel_data"
                          label="Select Panel"
                          v-model="updateModule.panel_id"
                        ></v-select>
                        <v-text-field
                          v-model="updateModule.module_icon"
                          label="Module Icon"
                          required
                        ></v-text-field>
                        <v-select
                          :items="module_sort"
                          label="Select Sort"
                          v-model="updateModule.module_sort"
                        ></v-select>
                      </v-container>
                      <v-container class="px-50" fluid> </v-container>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          @click="updateModuleData"
                          type="submit"
                        >
                          Update
                        </v-btn>
                        <v-btn class="close_button" text @click="toggleUpdateModal = false">
                          Close
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
	  </template>
     </v-dialog>
</v-row>
        <!-- v-data-table-ends -->
  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import Pagination from "./component/Pagination.vue";
import { parse } from '@babel/core';

export default {
  components: { Index, Buttons, Loading, Pagination },
  data() {
    return {
      toggleUpdateModal:false,
      //////table data starts here////
      selected: [],
      options: {},
      sort: "",
      total: 0,
      editReportMode: false,
      fromDateMenu: false,
      fromDateVal: null,
      //  minDate: "2020-01-05",
      //  maxDate: "2019-08-30",
      headers: [
        {
          text: "",
          value: "selectCheckbox",
          width: "50px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Parent Module",
          value: "parent_name",
          width: "100px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Modules",
          value: "module_name",
          width: "150px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Panel",
          value: "panel_name",
          width: "100px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Operations Allowed",
          value: "children",
          width: "100px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Modified Date",
          value: "modified_date",
          width: "100px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Action",
          value: "actions",
          width: "50px",
          sortable: false,
          class: "v-data-table-header",
        },
      ],
      ////// table data ends here
      valid: true,
      checkbox: false,
      operation: [],
      modules: [],
      module_data: [],
      isLoading: true,
      fullPage: true,
      dialog: false,
      dialog1: false,
      dialog2: false,
      deleteItems: [],
      checked_moduleId: [],
      checkcedstr: "",
      message1: "",
      message2: "",
      upmessage1: "",
      upmessage2: "",
      parent_id: [],
      module_sort: [],
      allSelected: false,
      new: [],
      panel_data: [],
      parent_modules: [],
      parent_module_data: [],
      p_id: "",
      m_id: "",
      addModule: {
        moduleName: null,
        parent_id: null,
        module_icon: "mdi-view-dashboard",
        parent_id: null,
        url_slug: null,
        panel_id: null,
      },
      updateModule: {
        moduleId: null,
        moduleName: null,
        operation_id: [],
        module_icon: null,
        module_sort: null,
        url_slug: null,
        panel_id: null,
      },
      checked: false,
      nameRules: [(v) => !!v || "Name is required"],
      urlRules: [(v) => !!v || "Url is required"],
      addModulelabel: null,
      //pagination code
      perpage: 50,
      totRecords: null,
      page: 1,
      isLoading: true,
      fullPage: true,
      query: "",
      showPerPage: false,
      ///
    };
  },
  mounted() {
      this.get_panel();
      this.get_Parent_modules();
    },
  computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    ///////
  },
  methods: {
     //pagination methods
    checkIfOperationExistForModule(type){
        return this.$utils.checkIfOperationExistForModule(this, type)
    },
    getDataByPage(value) {
      console.log(value);
      this.page = value;
      this.getModule(this.page);
    },
    handlePerPage(value) {
      console.log(value);
      this.perpage = value;
      this.getModule(this.page);
    },
    paginate(n) {
      console.log(n);
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getModule(this.page);
    },
    last(value) {
      console.log(value);
      this.page = this.lastPage;
      this.getModule(this.page);
    },
    /////
    resetSearch(){
        this.page = 1,
        this.query = ""
        this.p_id = ""
        this.m_id = ""
        this.$utils.setCookies('manage_search_query', '', this)
        this.$utils.setCookies('manage_p_id', '', this)
        this.$utils.setCookies('manage_m_id', '', this)
        this.getModule();
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.dialog = false;
      this.dialog1 = false;
      this.message1 = "";
      this.upmessage1 = "";
      this.moduleId = null;
      this.moduleName = null;
      this.updateModule.operation_id = [];
      this.updateModule.module_icon = null;
      this.updateModule.module_sort = null;
      this.updateModule.parent_id = null;
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
    },
    searchModules: function () {
      this.getModule(this.page, this.query, this.p_id, this.m_id);
    },
    getModule(page = "", query = "", p_id = "", m_id = "") {
      this.query = this.$utils.getCookies('manage_search_query')
      this.p_id = this.$utils.getCookies('manage_p_id') && this.$utils.getCookies('manage_p_id') != '' ? parseInt(this.$utils.getCookies('manage_p_id')) : ''
      this.panel_modules(this.p_id)
      this.m_id = this.$utils.getCookies('manage_m_id') && this.$utils.getCookies('manage_m_id') != '' ? parseInt(this.$utils.getCookies('manage_m_id')) : ''
      if(this.query == null){
        this.query = ""
      }
      if(this.p_id == null){
        this.p_id = ""
      }
      if(this.m_id == null){
        this.m_id = ""
      }
      let check = this;
      const path = check.$url("MODULE")+ "?q=" + this.query +"&p_id=" + this.p_id + "&m_id=" + this.m_id + "&page=" + page;
      check.isLoading = true;
      console.log(path + "checking path")
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: check  })
        .get(path)
        .then((res) => {
          check.isLoading = false;
          console.log(res.data.payload);
          this.modules = res.data.payload.module.modules_data;
          this.total = this.modules.length;
          this.totRecords = res.data.payload.module.total_records;
          this.pageNo = res.data.payload.module.page_numbers;
          let modules;
          this.module_data = [];
          modules = res.data.payload.module.modules_data;
          let i;
            for (i = 0; i < this.modules.length; i++) {
              let tempobj = {
                module_name: this.modules[i].module_name,
                id: this.modules[i].module_id,
                panel_name: this.modules[i].panel_name,
                parent_name: this.modules[i].parent_name,
                children: this.modules[i].children,
                // selected_children: this.modules[i].selected_children,
                // mom_id: this.modules[i].mom_id,
                roleId: parseInt(this.$route.params.id),
                module_id: this.modules[i].module_id,
                modified_date: "",
              };
              this.module_data.push(tempobj);
              let dateCov;
              dateCov = this.format_date(this.modules[i].modified_date);
              this.module_data[i].modified_date = dateCov;
              let child;
              let operations = [];
              let j;
              child = this.modules[i].children;
              for (j = 0; j < child.length; j++){
                  operations.push(child[j].name)
                  //console.log(operations)
              }
              this.module_data[i].children = operations;
            }

        //set operations
          if (res.data.payload.module.operations.length) {
            res.data.payload.module.operations.forEach(function (item) {
              let a = {
                text: item.operation_name,
                value: item.operation_id,
              };
              check.operation.push(a);
            });
          }

        //set parents
          if (res.data.payload.module.modules_data.length) {
            res.data.payload.module.modules_data.forEach(function (item) {
              if (item.parent_id == 0) {
                let a = {
                  text: item.module_name,
                  value: item.module_id,
                };
                check.parent_id[0] = "";
                check.parent_id.push(a);
              }
            });
          }
        //set sort
          if (res.data.payload.module.modules_data.length) {
            res.data.payload.module.modules_data.forEach(function (item) {
              let a = {
                text: item.module_id,
                value: item.module_id,
              };
              check.module_sort.push(a);
              check.module_sort = check.module_sort.sort((a, b) =>
                a.value > b.value ? 1 : b.value > a.value ? -1 : 0
              );
            });
          }
          // const path = this.$url("PANEL_DATA");
          // this.$fetch({ requiresAuth: true, operation : 'view', vueScope: check })
          //   .get(path)
          //   .then((res) => {
          //     if (res.data.payload.panelId.length) {
          //       res.data.payload.panelId.forEach(function (item) {
          //         let a = {
          //           text: item[1],
          //           value: item[0],
          //         };
          //         check.panel_data.push(a);
          //   });
          // }
          // });
        })
        .catch((error) => {
          console.error(error);
        });
    },

  get_Parent_modules() {
      let check = this;
      const endpoint_url = this.$url("GET_MODULES");
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
        .get(endpoint_url)
        .then((res) => {
          let modules = res.data.payload.modules;
          if (Array.isArray(modules) && modules.length) {
            modules.forEach((md)=>{
              if(md.parent_id == 0){
                  let tempobj = {
                    module_id:md.module_id,
                    module_name:md.module_name,
                    panel_id:md.panel_id
                  };
                  this.parent_modules.push(tempobj);
              }
            })
          }
        });
    },
    get_panel(){
      let check = this;
      const path = this.$url("PANEL_DATA");
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: check })
          .get(path)
          .then((res) => {
                if (res.data.payload.panelId.length) {
                  res.data.payload.panelId.forEach(function (item) {
                    let a = {
                      text: item[1],
                      value: item[0],
                    };
                    check.panel_data.push(a);
              });
            }
          });
    },
    panel_modules: function(id){
      this.parent_module_data = []
      let panel_id = id
      if(panel_id != null && panel_id != 0){
        this.parent_modules.forEach((m)=>{
          if(m.panel_id == panel_id){
            this.parent_module_data.push({text:m.module_name, value:m.module_id})
          }
        })
      }
      this.isModuleDdEditing = true
    },
    addModuleData(e) {
      e.preventDefault();
      let check = this;
      console.log(this.addModule.moduleName);
      if (
        this.addModule.moduleName != "" &&
        this.addModule.moduleName != null
      ) {
        this.$fetch({ requiresAuth: true, operation : 'add' , vueScope: check  })
          .post(this.$url("MODULE"), this.addModule)
          .then((result) => {
            console.log(result.data.payload);
            this.message1 = result.data.payload[0].moduleId;
            this.message2 = result.data.payload[0].moduleName;
            if (this.message2 == null) {
              this.message1 = result.data.payload[0].moduleId;
              this.dialog = true;
            } else {
              this.message1 = null;
              this.dialog = false;
              if (result.data.statusCode == 200 && this.message1 == null) {
                this.$swal.fire({
                  icon: "success",
                  title: "Record(s) added successfully",
                  text: result.data.payload.message,
                });
              }
              this.getModule(this.page, this.query);
              this.reset();
            }
            console.log(result);
          });
      }
    },
    addToUpdate(e, atchid) {
      let view = this;
      this.toggleUpdateModal = true
      view.modules.forEach(function (o, i) {
        if (o.module_id == atchid) {
          view.updateModule.moduleName = o.name;
          view.updateModule.moduleId = o.module_id;
          view.updateModule.module_sort = o.module_sort;
          view.updateModule.parent_id = o.parent_id;
          view.updateModule.module_icon = o.icon;
          view.updateModule.url_slug = o.url_slug;
          view.updateModule.panel_id = o.panel_id;
        }
      });
      view.updateModule.operation_id = []
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: view })
        .get(this.$url("MODULE"), {
          params: { ids: atchid },
        })
        .then((res) => {
          console.log(res.data.payload.module);
          if (res.data.payload.module[0].length) {
            res.data.payload.module[0].forEach(function (item) {
              console.log(item)
              view.updateModule.operation_id.push(item[0]);
            });
          }
        });
    },
    updateModuleData(e) {
      e.preventDefault();
      let data = this;
      if (
        data.updateModule.moduleName != "" &&
        data.updateModule.moduleName != null
      ) {
        this.$fetch({ requiresAuth: true, operation : 'edit', vueScope: data })
          .put(data.$url("MODULE"), data.updateModule)
          .then((result) => {
            this.upmessage1 = result.data.payload.moduleId;
            this.upmessage2 = result.data.payload.moduleName;
            if (this.upmessage2 == null) {
              this.upmessage1 = result.data.payload.moduleId;
              this.dialog1 = true;
            } else {
              this.upmessage1 = null;
              this.dialog1 = false;
              if (result.data.statusCode == 200) {
                this.$swal.fire({
                  icon: "success",
                  title: "Record(s) updated successfully",
                  text: result.data.payload.message,
                });
                this.toggleUpdateModal = false
                view.updateModule.operation_id = []
              }
              this.getModule(data.page, data.query);
              this.reset();
            }
            console.log(result);
          });
      }
    },
    addToDelete(e, atchid) {
      this.checkedthis = false;
      //e.target.checked
      if (e.target.checked) {
        if (this.checked_moduleId.indexOf(e.target.value) == -1) {
          this.checked_moduleId.push(e.target.value);
        }
      } else {
        this.checked_moduleId.splice(
          this.checked_moduleId.indexOf(e.target.value),
          1
        );
      }
      let checkcedstr = this.checked_moduleId.join(",");
      this.checkcedstr = checkcedstr;
      console.log(this.checkcedstr);
    },
    deleteModule(e) {
      e.preventDefault();
      let check = this;
      this.$fetch({ requiresAuth: true, operation : 'delete', vueScope: check })
        .delete(this.$url("MODULE"), {
          params: { id: this.deleteItems },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.$swal.fire({
              icon: "success",
              title: "Selected record(s) has been deleted",
              text: response.data.payload.message,
            });
          }
          this.getModule(this.page, this.query);
          this.deleteItems = [];
        });
    },
    selectAll: function () {
      let module = this.modules;
      if (this.allSelected == true) {
        if (Array.isArray(module) && module.length) {
          this.deleteItems = [];
          let i;
          for (i = 0; i < module.length; i++) {
            console.log(module[i]);
            this.deleteItems.push(module[i][0]);
          }
        }
      } else if (this.allSelected == false) {
        this.deleteItems = [];
      }
    },
    select: function () {
      this.allSelected = false;
    },
    submit() {
      this.$v.$touch();
    },
      searchModuleName: function () {
      this.getModule(this.page, this.query, this.p_id, this.m_id);
      console.log(this.query);
    },
  },
  watch: {
    '$store.state.Rawdata.current_active_module': function(o) {
    if(o){
    this.getModule(1);
    }
    },
    page: function (ob) {
    this.getModule(ob)
    },
    query: function(q){
      let view = this
      view.$utils.setCookies('manage_search_query', q, view)
    },
    p_id: function(p_id){
      let view = this
      if(p_id){
        view.$utils.setCookies('manage_p_id', p_id, view)
      }else{
        view.$utils.setCookies('manage_p_id', '', view)
      }
    },
    m_id: function(m_id){
      let view = this
      if(m_id){
        view.$utils.setCookies('manage_m_id', m_id, view)
      }else{
        view.$utils.setCookies('manage_m_id', '', view)
      }
    },
 },
};
</script>
